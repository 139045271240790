<script setup lang="ts">
import type { News } from "~/types/directus-collections.d.ts";

defineProps<{
  news: News[] | undefined;
}>();

const newsScroller = ref<HTMLElement>();
const newsPrevEnabled = ref(false);
const newsNextEnabled = ref(false);

onMounted(() => {
  if (!newsScroller.value) return;
  newsScroller.value.addEventListener("scroll", () => {
    if (!newsScroller.value) return;

    newsPrevEnabled.value = !(newsScroller.value.scrollLeft < 10);
    newsNextEnabled.value = !(newsScroller.value.scrollLeft > newsScroller.value.scrollWidth - newsScroller.value.offsetWidth - 10);
  });
  // Trigger the event to update the button states
  newsScroller.value.dispatchEvent(new Event("scroll"));
});

function scrollNext() {
  if (!newsScroller.value) return;
  newsScroller.value.scrollBy({
    left: newsScroller.value.offsetWidth / 2,
    top: 0,
    behavior: "smooth",
  });
}
function scrollPrev() {
  if (!newsScroller.value) return;
  newsScroller.value.scrollBy({
    left: -(newsScroller.value.offsetWidth / 2),
    top: 0,
    behavior: "smooth",
  });
}
</script>

<template>
  <div v-if="news?.length">
    <div ref="newsScroller" class="scrollbar-hide mb-16 flex snap-x snap-mandatory flex-row gap-x-4 overflow-x-auto overflow-y-hidden">
      <NewsPreview
        v-for="(item, index) in news"
        :key="index"
        :item="item"
        class="min-w-[calc(75%_-_1rem)] snap-start md:min-w-[calc(33.33333%_-_1rem)] xl:min-w-[calc(25%_-_1rem)] xl:max-w-[calc(25%_-_1rem)]"
      />
    </div>
    <div class="flex flex-row justify-center gap-x-3 xl:justify-end">
      <CommonButtonNuxtLink
        icon="fa-solid fa-arrow-left"
        icon-size="xl"
        :disabled="!newsPrevEnabled"
        type="button"
        @click="scrollPrev"
      ></CommonButtonNuxtLink>
      <CommonButtonNuxtLink
        icon="fa-solid fa-arrow-right"
        icon-size="xl"
        :disabled="!newsNextEnabled"
        type="button"
        @click="scrollNext"
      ></CommonButtonNuxtLink>
    </div>
  </div>
  <div v-else class="text-center font-script text-xl text-accentDark md:text-2xl xl:text-4xl">No news available</div>
</template>

<style lang="postcss">
/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
</style>
